import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import RequestForm from '../components/requestForm';
import Hero from '../components/hero';

function Request(props) {

  return (
    <Layout>
      <Hero title="Request Appointment" color="light" />
      <section className="section content">
        <div className="container">
          <div className="columns">
            <div className="column is-half">
                <p>
                  Please submit your contact information and a member of our staff will be in touch
                  shortly to book your appointment. Remember, this is only a request form - your
                  appointment is not booked until you've received confirmation from our staff.
                </p>
                <div className="notification ">
                  <p>
                  <strong>
                    Do not use this form for emergency vision care. If you experience an emergency,
                    call 911 immediately.
                  </strong>
                </p>
              </div>
            </div>
            <div className="column is-half">
              <RequestForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

Request.propTypes = {};

export default Request;

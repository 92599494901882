import React from 'react';
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
// import 'react-dates/lib/css/_datepicker.css';
import NetlifyForm from 'react-netlify-form';

import Button from './button';

class RequestForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      date: null,
      focused: false,
    };
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {}

  handleDayChange(date) {
    this.setState({
      date,
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { firstname, lastname, email, phone, date, focused } = this.state;
    const form = {
      firstname,
      lastname,
      email,
      phone,
      date,
    };

    return (
      <form
        name="request"
        method="POST"
        data-netlify="true"
        action="/confirmation"
        netlify-honeypot="winniethepooh"
      >
        <div className="field">
          <label className="label" htmlFor="firstname">
            First Name
          </label>
          <div className="control">
            <input
              className="input is-medium"
              name="firstname"
              required
              value={firstname}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor="lastname">
            Last Name
          </label>
          <div className="control">
            <input
              className="input is-medium"
              name="lastname"
              required
              value={lastname}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor="email">
            Email
          </label>
          <div className="control">
            <input
              className="input is-medium"
              type="email"
              name="email"
              required
              value={email}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor="phone">
            Phone
          </label>
          <div className="control">
            <input
              className="input is-medium"
              type="tel"
              name="phone"
              required
              value={phone}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor="phone">
            Preferred Date (optional)
          </label>
          <div className="control">
            <SingleDatePicker
              date={date} // momentPropTypes.momentObj or null
              onDateChange={date => this.setState({ date })} // PropTypes.func.isRequired
              focused={focused} // PropTypes.bool
              onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
              id="datepicker" // PropTypes.string.isRequired,
              // withPortal
              block
              openDirection="up"
              numberOfMonths={3}
              withPortal
              // renderCalendarInfo={() => <div className="notification">something something</div>}
              // calendarInfoPosition="before"
              // renderCalendarInfo={<div className="notification">This is the information</div>}
            />
            {/* <DatePicker calendarType="US" onChange={this.handleDayChange} value={date} /> */}
            {/* <input className="input is-medium" type="date" name="date" value={date} /> */}
          </div>
        </div>
        <input
          type="text"
          id="winniethepooh"
          name="winniethepooh"
          tabIndex="-1"
          style={{ display: 'none' }}
        />
        <input type="hidden" name="form-name" value="request" />
        <hr />
        <Button type="submit" color="primary" size="medium">
          Submit
        </Button>{' '}
        <Button type="reset" size="medium">
          Reset
        </Button>
      </form>
    );
  }
}

RequestForm.propTypes = {};
RequestForm.defaultProps = {};

export default RequestForm;
